import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoutes";
import Signup from "./components/Auth/Signup";
import DocumentVerification from "./components/Auth/DocumentVerification";
import ResetPassword from "./components/Auth/ResetPaasword";
import Verification from "./components/Auth/Verification";
import VendorVerification from "./components/Auth/VendorVerification";
import Document from "./components/Auth/Document";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Page404 from "./components/Auth/Page404";
import SignIn from "./components/Auth/SignIn";
import Dashboard from "./screens/Dashboard/Dashboard";
import Subscription from "./screens/Subscription/Subscription";
import ProductList from "./screens/Product/ProductList";
import ProductOffer from "./screens/Product/ProductOffer";
import SingleProductDetails from "./screens/Product/SingleProductDetails";
import ProductDetails from "./screens/Product/ProductDetails";
import UserReportDetails from "./screens/Help_Support/UserReportDetails";
import ProductOfferDetails from "./screens/Product/ProductOfferDetails";
import ProductFormList from "./screens/Product/ProductFormList";
import AddDynamicForm from "./screens/Product/AddDynamicForm";
import FAQ from "./screens/Help_Support/FAQ";
import HelpSupport from "./screens/Help_Support/HelpSupport";
import UserReport from "./screens/Help_Support/UserReport";
import BuyerList from "./screens/Customers/Buyer";
import BuyerDetails from "./screens/Customers/BuyerProfile";
import VendorList from "./screens/Customers/Vendor";
import CategoryList from "./screens/Product/Category";
import SubCategoryList from "./screens/Product/SubCategory";
import VendorDetails from "./screens/Customers/VendorDetails";
import PublicRoute from "./PublicRoutes";
import { logout } from "./helper/logout";
import Chat from "./screens/App/Chat";
import ManagePlans from "./screens/Subscription/ManagePlans";
import AdminList from "./screens/Customers/Admin";
import { getSubdomain } from "./helper/subdomainRouting";

const App = () => {
  const subdomain = getSubdomain();

  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("token");
  const userRole = localStorage.getItem("role"); // 'admin' or 'vendor'

  useEffect(() => {
    const events = ["mousemove", "keydown", "scroll"];
    let logoutTimer;

    const resetTimer = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        logout();
        navigate(`/sign-in/`, { replace: true });
      }, 360000000); // 1 hour in milliseconds
    };

    const handleActivity = () => {
      resetTimer();
    };

    events.forEach((event) => {
      window.addEventListener(event, handleActivity);
    });

    // Set initial timer
    resetTimer();

    // Cleanup event listeners on component unmount
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleActivity);
      });
      clearTimeout(logoutTimer);
    };
  }, []);

  return (
    <Routes>
      {subdomain === "admin" ? (
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              allowedRoles={["admin"]}
              userRole={userRole}
            />
          }
        >
          {/* Protected routes for Admin */}
          <Route path="/" element={<Dashboard subdomain={subdomain} />} />
          <Route
            path="/dashboard"
            element={<Dashboard subdomain={subdomain} />}
          />
          <Route path="/chat" element={<Chat />} />
          <Route path="/manage-plans" element={<ManagePlans />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:sender_id/" element={<Chat />} />
          <Route path="/chat/:sender_id/:receiver_id" element={<Chat />} />
          <Route
            path="/single-product-details"
            element={<SingleProductDetails />}
          />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/user-report-details" element={<UserReportDetails />} />
          <Route
            path="/product-offer-details"
            element={<ProductOfferDetails />}
          />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/productform-list" element={<ProductFormList />} />
          <Route
            path="/product/add-form"
            element={<AddDynamicForm type="add" />}
          />
          <Route
            path="/product/edit-form/:id"
            element={<AddDynamicForm type="edit" />}
          />
          <Route
            path="/product-report-details"
            element={<UserReportDetails />}
          />
          <Route
            path="/product/view-form/:id"
            element={<AddDynamicForm type="view" />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/issues" element={<HelpSupport />} />
          <Route path="/user-report" element={<UserReport />} />
          <Route path="/admin-list" element={<AdminList />} />
          <Route path="/buyer-list" element={<BuyerList />} />
          <Route path="/product-list" element={<ProductList />} />

          <Route path="/buyer-details" element={<BuyerDetails />} />
          <Route path="/vendor-list" element={<VendorList />} />
          <Route path="/vendor-details" element={<VendorDetails />} />
          <Route path="/category-list" element={<CategoryList />} />
          <Route path="/subcategory-list" element={<SubCategoryList />} />
        </Route>
      ) : (
        <Route
          element={
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              allowedRoles={["vendor"]}
              userRole={userRole}
            />
          }
        >
          {/* Protected routes for Vendor */}
          <Route path="/" element={<Dashboard subdomain={subdomain} />} />
          <Route
            path="/dashboard"
            element={<Dashboard subdomain={subdomain} />}
          />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat/:sender_id/" element={<Chat />} />
          <Route path="/chat/:sender_id/:receiver_id" element={<Chat />} />
          {/* <Route path="/subscription" element={<Subscription />} /> */}
          <Route path="/product-offer" element={<ProductOffer />} />
          <Route
            path="/single-product-details"
            element={<SingleProductDetails />}
          />
          <Route path="/product-list" element={<ProductList />} />
          <Route path="/product-details" element={<ProductDetails />} />

          <Route
            path="/product-offer-details"
            element={<ProductOfferDetails />}
          />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/issues" element={<HelpSupport />} />
          <Route path="/vendor-details" element={<VendorDetails />} />
          {/* Add other vendor-specific routes here */}
        </Route>
      )}

      {/* Auth routes */}
      <Route element={<PublicRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/sign-in/" element={<SignIn subdomain={subdomain} />} />
        <Route path="/sign-up" element={<Signup subdomain={subdomain} />} />
        <Route
          path="/documents-verification"
          element={<DocumentVerification />}
        />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verification" element={<Verification />} />
        <Route path="/documents" element={<Document />} />
        <Route path="/vendor-verification" element={<VendorVerification />} />
        {/* <Route path="/otp-verification" element={<OTP />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/page-404" element={<Page404 />} />
      </Route>

      {/* Not Authorized Route */}
      {/* <Route path="/not-authorized" element={<NotAuthorized />} /> */}
    </Routes>
  );
};

export default App;



// removed menu
// {
//   "name": "Membership Plans",
//   "routerLink": ["subscription"],
//   "identifier": "Membership Plans",
//   "iconClass": "icofont-bell-alt fs-5",
//   "breadcrumbMessage": "Membership Plans",
//   "isCategory": false,
//   "isApp": false,
//   "children": []
// },
